import { Controller } from "@hotwired/stimulus"
import { TempusDominus } from '@eonasdan/tempus-dominus'

// Connects to data-controller="application"
export default class extends Controller {
  disableAutocompletion() {
    $('.no-autocomplete').attr('autocomplete', 'off')
  }

  initializeDatepickers() {
    Array.from(document.getElementsByClassName('datepicker-control')).forEach(
      function(element, index, array) {
        new TempusDominus(element, {
	  display: {
	     icons: {
		type: 'icons',
		previous: 'bi bi-chevron-left',
		next: 'bi bi-chevron-right'
	      },
	    viewMode: 'calendar',
	    components: {
              calendar: true,
              date: true,
              month: true,
              year: true,
              decades: true,
              clock: false,
              hours: false,
              minutes: false,
              seconds: false,
	    }
	  },
	  localization: {
	    format: 'yyyy/MM/dd',
	    dayViewHeaderFormat: 'MMMM yyyy'
	  }
	}
	)
      }
    )
  }
}
